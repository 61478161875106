.checkbox-text{
  white-space: normal;
  font-size: $font-size-14;
}

.checkbox-hint{
  white-space: normal;
  font-size: $font-size-12;
}

.mat-mdc-checkbox .mdc-form-field .mat-mdc-form-field-hint {
  display: inline-block;
  line-height: $font-size-16 !important;
}

// adds breaks to the mat-checkboxes with long labels
.mat-mdc-checkbox .mdc-form-field{
  white-space: normal !important;
  font-size: inherit !important;
}

// rather than center the checkbox, put the checkbox in the first line
.mdc-checkbox{
  --mdc-checkbox-selected-checkmark-color: white !important;
}
