.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 1.4rem;
  align-items: baseline;
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 1.6rem;
  }
  &.--centered {
    justify-content: center;
    .form__field {
      margin-left: auto;
      margin-right: auto;
    }
  }
  // form width set in parent
  .mat-mdc-form-field {
    width: 100%;
  }
  &__field {
    &__textarea {
      min-height: 6rem;
      &.--long {
        min-height: 18.4rem;
      }
    }
    &.--large {
      width: 60rem;
      max-width: 100%;
    }
    &.--medium {
      width: 45rem;
      max-width: 100%;
    }
    &.--normal {
      width: 25rem;
    }

    &.--small {
      .mat-mdc-form-field {
        font-size: 1.4rem;
      }
      .mat-mdc-floating-label {
        font-size: 1.4rem;
      }
      .mat-mdc-select {
        font-size: 1.4rem;
      }
      // mat-mdc-option font size cannot be changed because element isnt child of form__field
    }
    &.--tiny {
      .mat-mdc-form-field {
        font-size: 1.2rem;
      }
      .mat-mdc-floating-label {
        font-size: 1.2rem;
      }
      .mat-mdc-select {
        font-size: 1.2rem;
      }
      // mat-mdc-option font size cannot be changed because element isnt child of form__field
    }
    &.--auto {
      width: auto;
      flex-grow: 1;
    }
    &.--full {
      width: 100%;
    }
  }
  &.--inline {
    flex-direction: row;
    flex-wrap: wrap;
    .form__field {
      &:not(:last-child) {
        margin-right: .8rem;
      }
    }
  }
  &.--one-line {
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1 1 0;
    gap: 1.6rem;
    .form__field {
      width: 100%;
    }
  }
  &.--one-line-lg {
    @media screen and (min-width: $breakpoint-lg) {
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      flex: 1 1 0;
      gap: 1.6rem;
    }
    .form__field {
      @media screen and (max-width: $breakpoint-lg) {
        width: 100%;
      }
    }
  }
  &.--one-line-xs {
    @media screen and (min-width: $breakpoint-xs) {
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      flex: 1 1 0;
      gap: 1.6rem;
    }
    .form__field {
      @media screen and (max-width: $breakpoint-xs) {
        width: 100%;
      }
    }
  }

}
// material components changes
// prevent material default color overwrite
.mdc-text-field {
  background-color: inherit !important;
}
// options of select fields : padding between select options
.mdc-list-item__primary-text {
  padding: .8rem 0;
}
// options select down arrow would changes input size otherwise
.mat-mdc-select {
  .mat-mdc-select-arrow-wrapper {
    height: 1em;
  }
}
.custom-element-on-background-color {
  .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
      color: var(--custom-element-on-background-color);
    }
  }
  .mat-mdc-select {
    .mat-mdc-select-arrow-wrapper {
      .mat-mdc-select-arrow {
        color: var(--custom-element-on-background-color);
      }
    }
  }
  .mat-mdc-checkbox {
    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"]) ~ .mdc-checkbox__background {
      color: var(--custom-element-on-background-color);
      border-color: var(--custom-element-on-background-color);     
    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
      color: var(--custom-element-on-background-color);
      border-color: var(--custom-element-on-background-color);      
    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
      color: var(--custom-element-on-background-color);
      border-color: var(--custom-element-on-background-color); 
    }
  }
  .mat-mdc-form-field-error {
    color: var(--custom-element-on-background-color);
  }
}
.custom-text-color {
  .mat-mdc-checkbox .mdc-form-field {
    color: var(--custom-text-color);
  }
  .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
      color: var(--custom-text-color);
    }
  }
  .mat-mdc-select {
    .mat-mdc-select-arrow-wrapper .mdc-checkbox__background {
      .mat-mdc-select-arrow {
        color: var(--custom-text-color);
      }
    }
  }
  .mat-mdc-checkbox {
    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"]) ~ .mdc-checkbox__background {
      color: var(--custom-text-color);
      border-color: var(--custom-text-color);     
    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
      color: var(--custom-text-color);
      border-color: var(--custom-text-color);      
    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
      color: var(--custom-text-color);
      border-color: var(--custom-text-color); 
    }
  }
  .mat-mdc-form-field-error {
    color: var(--custom-text-color);
  }
}
.form__field {
  .mat-mdc-select {
    line-height: normal;
  }
}
// fix hint color : https://github.com/angular/components/issues/27677
.mat-form-field-disabled {
  .mat-mdc-form-field-subscript-wrapper {
    opacity: 0.5;
  }
}
