.facing-cards{
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: $breakpoint-lg) {
    flex-direction: row;
  }
  &__card{
    text-align: center;
    background-color: $reelax-white;
    padding: 4rem;
    border-radius: .4rem;
    flex: 1;
    position: relative;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
    margin: .8rem 0;
    @media screen and (min-width: $breakpoint-lg) {
      padding: 4.8rem;
      margin: 0 .8rem;
    }
    &__icon{
      position: absolute;
      z-index: 2;
      transform: translate(-50%,-50%);
      border-radius: 50%;
      text-align: center;
      background-color: var(--custom-background-color);
      color: var(--custom-event-color);
      left: 50%;
      top: calc(100% + .8rem); // .8rem is padding between two cards
      @media screen and (min-width: $breakpoint-lg) {
        left: calc(100% + .8rem); // .8rem is padding between two cards
        top: 50%;
      }
      &__img{
        width: 3.8rem;
        height: auto;
        margin: 1.6rem 1.6rem 0.8rem 1.6rem;
      }
      .mat-icon{
        width: 2.4rem;
        height: 2.4rem;
        margin: 2rem;
      }
    }
    &__title{
      font-size: 1.8rem;
      font-weight: 600;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: 2rem;
      }
    }
    &__field {
      display: inline-flex;
      font-weight: 600;
      text-align: center !important;
      max-width: 20rem;
      width: auto;
      &.mat-mdc-form-field, .mat-mdc-form-field{
        font-size: 2rem;
        width: 100%;
        .mat-mdc-form-field-flex {
          align-items: baseline !important;
        }
        .mat-mdc-form-field-infix{
          width: unset;
        }
      }
      @media screen and (min-width: $breakpoint-lg) {
        &.mat-mdc-form-field, .mat-mdc-form-field{
          font-size: 2.4rem;
        }
      }

      input {
        text-align: inherit;
      }
      &__hint{
        font-weight: 400;
        font-size: 1.2rem;
        text-align: left;
        margin-top: 1.6rem;
        position: absolute;
        width: 250%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.6rem;
        color: rgba(0, 0, 0, 0.54);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: 1.4rem;
        }
      }
      &__error{
        font-weight: 400;
        font-size: 1.2rem;
        color: $reelax-danger;
        text-align: left;
        @media screen and (min-width: $breakpoint-lg) {
          font-size: 1.4rem;
        }
      }
    }
    &__info-details-link{
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--custom-event-color);
      font-weight: 600;
      font-size: 1.2rem;
      margin-top: .8rem;
      margin-bottom: .8rem;
      span {
        margin-right: .4rem;
      }
      &:not(span) {
        cursor: pointer;
      }
      @media screen and (min-width: $breakpoint-lg) {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
      }
    }
    &__information{
      font-weight: 400;
      font-size: 1.2rem;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: 1.4rem;
      }
    }
    &__after-price {
      font-size: 1.2rem;
      color: rgba(0, 0, 0, 0.54);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: 1.4rem;
      }
    }
  }
}
