.title {
  font-weight: 800;
  font-family: $accent-font-family;
  font-family: var(--custom-accent-font-family);
  &.--page {
    font-size: 2.4rem;
    margin-top: 4rem;
    text-align: center;
    @media screen and (min-width: $breakpoint-lg) {
      font-size: 3.2rem;
    }
  }
  &.--home {
    font-size: 2.4rem;
    @media screen and (min-width: $breakpoint-sm) {
      font-size: 3.2rem;
    }
    @media screen and (min-width: $breakpoint-lg) {
      font-size: 4rem;
    }
  }
  span {
    font-family: inherit;
  }
  &.--left {
    text-align: left !important;
  }
}
.title-details {
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 2.4rem;
  font-style: italic;
}
.subtitle{
  font-weight: 600;
  font-size: 2.4rem;
  display: flex;

  &.--center {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    font-size: 2.4rem;
    height: 2.4rem;
    width: auto;
    margin-right: .4rem;
  }

  &.--text-size {
    font-size: 1.6rem;
    @media screen and (min-width: $breakpoint-md) {
      font-size: 2rem;
    }
  }
}
