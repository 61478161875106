.stepper{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2.4rem;
  font-size: 2rem;
  font-weight: 600;

  &__step{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $reelax-dark-grey;
    // Width is set in the components. (usually, width = 1 / nbr of steps)
    &.--active{
      color: var(--custom-event-color);

      .stepper__step__num{
        color: var(--custom-text-color);
        background: var(--custom-event-color);
      }
      .stepper__step__title{
        display: block;
      }
    }

    &__num{
      color: $reelax-white;
      background: $reelax-dark-grey;
      border-radius: 0.8em;
      -moz-border-radius: 0.8em;
      -webkit-border-radius: 0.8em;
      display: inline-block;
      font-weight: bold;
    }

    &__icon, &__num{
      line-height: 1.6em;
      text-align: center;
      width: 1.6em;
    }

    &__title{
      padding-top: 1.6rem;
      display: none;
      @media screen and (min-width: $breakpoint-lg) {
        display: block;
      }
    }
  }
  &.--menu-style {
    font-size: 1.6rem;
    font-weight: 400;
    @media screen and (min-width: $breakpoint-lg) {
      font-size: 2rem;
    }
    .stepper__step {
      &:hover:not(.--disabled):not(.--active) {
        cursor: pointer;
        color: $reelax-black;
      }
      &.--active {
        font-weight: 600;
      }
    }
  }
}