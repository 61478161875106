/* #### MARGIN #### */
/* full margin */
.m-0{
  margin: $spacing-size-0 !important;
}
.m-1{
  margin: $spacing-size-1 !important;
}
.m-2{
  margin: $spacing-size-2 !important;
}
.m-3{
  margin: $spacing-size-3 !important;
}
.m-4{
  margin: $spacing-size-4 !important;
}
.m-5{
  margin: $spacing-size-5 !important;
}
.m-auto{
  margin: auto !important;
}
/* margin-top */
.mt-0{
  margin-top: $spacing-size-0 !important;
}
.mt-1{
  margin-top: $spacing-size-1 !important;
}
.mt-2{
  margin-top: $spacing-size-2 !important;
}
.mt-3{
  margin-top: $spacing-size-3 !important;
}
.mt-4{
  margin-top: $spacing-size-4 !important;
}
.mt-5{
  margin-top: $spacing-size-5 !important;
}
.mt-auto{
  margin-top: auto !important;
}
/* margin-right */
.mr-0{
  margin-right: $spacing-size-0 !important;
}
.mr-1{
  margin-right: $spacing-size-1 !important;
}
.mr-2{
  margin-right: $spacing-size-2 !important;
}
.mr-3{
  margin-right: $spacing-size-3 !important;
}
.mr-4{
  margin-right: $spacing-size-4 !important;
}
.mr-5{
  margin-right: $spacing-size-5 !important;
}
.mr-auto{
  margin-right: auto !important;
}
/* margin-bottom */
.mb-0{
  margin-bottom: $spacing-size-0 !important;
}
.mb-1{
  margin-bottom: $spacing-size-1 !important;
}
.mb-2{
  margin-bottom: $spacing-size-2 !important;
}
.mb-3{
  margin-bottom: $spacing-size-3 !important;
}
.mb-4{
  margin-bottom: $spacing-size-4 !important;
}
.mb-5{
  margin-bottom: $spacing-size-5 !important;
}
.mb-auto{
  margin-bottom: auto !important;
}
/* margin-left */
.ml-0{
  margin-left: $spacing-size-0 !important;
}
.ml-1{
  margin-left: $spacing-size-1 !important;
}
.ml-2{
  margin-left: $spacing-size-2 !important;
}
.ml-3{
  margin-left: $spacing-size-3 !important;
}
.ml-4{
  margin-left: $spacing-size-4 !important;
}
.ml-5{
  margin-left: $spacing-size-5 !important;
}
.ml-auto{
  margin-left: auto !important;
}
/* margin-top and margin-bottom */
.my-0{
  margin-top: $spacing-size-0 !important;
  margin-bottom: $spacing-size-0 !important;
}
.my-1{
  margin-top: $spacing-size-1 !important;
  margin-bottom: $spacing-size-1 !important;
}
.my-2{
  margin-top: $spacing-size-2 !important;
  margin-bottom: $spacing-size-2 !important;
}
.my-3{
  margin-top: $spacing-size-3 !important;
  margin-bottom: $spacing-size-3 !important;
}
.my-4{
  margin-top: $spacing-size-4 !important;
  margin-bottom: $spacing-size-4 !important;
}
.my-5{
  margin-top: $spacing-size-5 !important;
  margin-bottom: $spacing-size-5 !important;
}
.my-auto{
  margin-top: auto !important;
  margin-bottom: auto !important;
}
/* margin-right and margin-left */
.mx-0{
  margin-right: $spacing-size-0 !important;
  margin-left: $spacing-size-0 !important;
}
.mx-1{
  margin-right: $spacing-size-1 !important;
  margin-left: $spacing-size-1 !important;
}
.mx-2{
  margin-right: $spacing-size-2 !important;
  margin-left: $spacing-size-2 !important;
}
.mx-3{
  margin-right: $spacing-size-3 !important;
  margin-left: $spacing-size-3 !important;
}
.mx-4{
  margin-right: $spacing-size-4 !important;
  margin-left: $spacing-size-4 !important;
}
.mx-5{
  margin-right: $spacing-size-5 !important;
  margin-left: $spacing-size-5 !important;
}
.mx-auto{
  margin-right: auto !important;
  margin-left: auto !important;
}
.mx-auto-on-large {
  @media screen and (min-width: $breakpoint-lg) {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

/* #### PADDING #### */
/* full padding */
.p-0{
  padding: $spacing-size-0 !important;
}
.p-1{
  padding: $spacing-size-1 !important;
}
.p-2{
  padding: $spacing-size-2 !important;
}
.p-3{
  padding: $spacing-size-3 !important;
}
.p-4{
  padding: $spacing-size-4 !important;
}
.p-5{
  padding: $spacing-size-5 !important;
}
/* padding-top */
.pt-0{
  padding-top: $spacing-size-0 !important;
}
.pt-1{
  padding-top: $spacing-size-1 !important;
}
.pt-2{
  padding-top: $spacing-size-2 !important;
}
.pt-3{
  padding-top: $spacing-size-3 !important;
}
.pt-4{
  padding-top: $spacing-size-4 !important;
}
.pt-5{
  padding-top: $spacing-size-5 !important;
}
/* padding-right */
.pr-0{
  padding-right: $spacing-size-0 !important;
}
.pr-1{
  padding-right: $spacing-size-1 !important;
}
.pr-2{
  padding-right: $spacing-size-2 !important;
}
.pr-3{
  padding-right: $spacing-size-3 !important;
}
.pr-4{
  padding-right: $spacing-size-4 !important;
}
.pr-5{
  padding-right: $spacing-size-5 !important;
}
/* padding-bottom */
.pb-0{
  padding-bottom: $spacing-size-0 !important;
}
.pb-1{
  padding-bottom: $spacing-size-1 !important;
}
.pb-2{
  padding-bottom: $spacing-size-2 !important;
}
.pb-3{
  padding-bottom: $spacing-size-3 !important;
}
.pb-4{
  padding-bottom: $spacing-size-4 !important;
}
.pb-5{
  padding-bottom: $spacing-size-5 !important;
}
/* padding-left */
.pl-0{
  padding-left: $spacing-size-0 !important;
}
.pl-1{
  padding-left: $spacing-size-1 !important;
}
.pl-2{
  padding-left: $spacing-size-2 !important;
}
.pl-3{
  padding-left: $spacing-size-3 !important;
}
.pl-4{
  padding-left: $spacing-size-4 !important;
}
.pl-5{
  padding-left: $spacing-size-5 !important;
}
/* padding-top and padding-bottom */
.py-0{
  padding-top: $spacing-size-0 !important;
  padding-bottom: $spacing-size-0 !important;
}
.py-1{
  padding-top: $spacing-size-1 !important;
  padding-bottom: $spacing-size-1 !important;
}
.py-2{
  padding-top: $spacing-size-2 !important;
  padding-bottom: $spacing-size-2 !important;
}
.py-3{
  padding-top: $spacing-size-3 !important;
  padding-bottom: $spacing-size-3 !important;
}
.py-4{
  padding-top: $spacing-size-4 !important;
  padding-bottom: $spacing-size-4 !important;
}
.py-5{
  padding-top: $spacing-size-5 !important;
  padding-bottom: $spacing-size-5 !important;
}
/* padding-right and padding-left */
.px-0{
  padding-right: $spacing-size-0 !important;
  padding-left: $spacing-size-0 !important;
}
.px-1{
  padding-right: $spacing-size-1 !important;
  padding-left: $spacing-size-1 !important;
}
.px-2{
  padding-right: $spacing-size-2 !important;
  padding-left: $spacing-size-2 !important;
}
.px-3{
  padding-right: $spacing-size-3 !important;
  padding-left: $spacing-size-3 !important;
}
.px-4{
  padding-right: $spacing-size-4 !important;
  padding-left: $spacing-size-4 !important;
}
.px-5{
  padding-right: $spacing-size-5 !important;
  padding-left: $spacing-size-5 !important;
}
// SPACING
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.large-fixed-height {
  height: 120rem;
}
