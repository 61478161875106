.hide-on-extra-small-only{
  display: none;
  visibility: hidden;
  @media screen and (min-width: $breakpoint-sm){
    display: inherit;
    visibility: visible;
  }
}
.show-on-extra-small-only{
  @media screen and (min-width: $breakpoint-sm){
    display: none;
    visibility: hidden;
  }
}
.hide-on-small-only{
  display: none;
  visibility: hidden;
  @media screen and (min-width: $breakpoint-md){
    display: inherit;
    visibility: visible;
  }
}
.show-on-small-only{
  @media screen and (min-width: $breakpoint-md){
    display: none;
    visibility: hidden;
  }
}
.show-on-medium-only{
  display: none;
  visibility: hidden;
  @media screen and (min-width: $breakpoint-md) {
    display: inherit;
    visibility: visible;
  }
  @media screen and (min-width: $breakpoint-lg){
    display: none;
    visibility: hidden;
  }
}
.hide-on-large-only{
  @media screen and (min-width: $breakpoint-lg){
    display: none;
    visibility: hidden;
  }
}
.show-on-large-only{
  display: none;
  visibility: hidden;
  @media screen and (min-width: $breakpoint-lg){
    display: inherit;
    visibility: visible;
  }
}