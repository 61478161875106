.checkbigbox{
  font-size: $font-size-14;
  margin: .4rem;
  background: $reelax-white;
  border: 1px solid $reelax-black;
  border-radius: .4rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
  // transition: all $ngcvo-anim-time $ngcvo-anim-curve;

  &:hover, &:focus{
    background: $reelax-light-grey;
  }

  &.-active{
    background: $reelax-green;
    color: $reelax-black;
    border-color: $reelax-black;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  &.mat-radio-disabled, &[disabled]{
    color: $reelax-light-grey;
    border-color: $reelax-light-grey;
    box-shadow: none;
    cursor: default;

    .checkbigbox-caption, .checkbigbox-label{
      color: $reelax-light-grey;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-label{
    width: 100%;
    height: 100%;
    padding-left: 0;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-label-content{
    width: 100%;
    height: 100%;
    padding: 0 0.5em;
    position: relative;
  }

  &.mat-mdc-radio-button{
    position: relative;
    display: inline-block;
    width: 10rem;
    height: 10rem;
    margin-right: $spacing-size-3;
    vertical-align: top;

    /*@include respond-to(phone){
      width: 100%;
      margin-right: 0;
    }*/
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-container{
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      * {
        display: none;
      }
    }
  }

  &-icon{
    // @include flex-align(center, flex-end);
    height: 51%;
    font-size: 1.4em;
    padding-bottom: 0.5em;
    z-index: 0;
  }

  &-label{
    // @include flex-align;
    text-align: center;
    white-space: pre-wrap;
    z-index: 0;
  }

  &-sub-label{
    position: absolute;
    left: 0;
    bottom: $spacing-size-1;
    display: block;
    text-align: center;
    width: 100%;
  }

  &-caption{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: $spacing-size-1;
    text-align: center;
    white-space: pre-wrap;
    color: $reelax-black;
  }

  &-tooltip-trigger{
    position: absolute;
    bottom: $spacing-size-1;
    right: $spacing-size-1;
    font-size: $font-size-12;
    z-index: 2;
  }
}
