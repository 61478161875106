.typing {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid var(--custom-event-color); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin-bottom: 0;
  max-width: max-content;
  --blink-caret-color: var(--custom-event-color);

  &.--primary {
    --blink-caret-color: $reelax-primary;
    border-right: .15em solid $reelax-primary;
  }
  &.--first {
    animation: 
      typing-animation-1 12s infinite,
      blink-caret-animation 1s step-end infinite;
  }
  &.--second {
    animation: 
      typing-animation-2 12s infinite,
      blink-caret-animation 1s step-end infinite;
  }
  &.--third {
    animation: 
      typing-animation-3 12s infinite,
      blink-caret-animation 1s step-end infinite;
  }
}
