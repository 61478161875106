.form-group-section {
  margin-bottom: 1.6rem;
  width: 100%;

  &__item{
    border-left: 4px solid var(--custom-event-color);
    padding-left: .8rem;
    margin: 2.4rem 0;
  }
}

.form-explanation, .form-explanation-toggle {
  font-weight: 400;
  font-style: italic;
  font-size: 1.2rem;
  margin: 1.2rem 0;
}
.form-explanation-toggle{
  color: black;
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
}
.form-explanation{
  border-left: 2px solid $reelax-primary;
  padding-left: .8rem;
  &__item{
    font-style: italic;
    color: rgba(0, 0, 0, .54);
    margin-top: .8rem;
    margin-bottom: 1.6rem;
  }
}
