@keyframes appearingAnimation {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

// home-fan
@keyframes slidingFromLeft {
  0% {
    opacity: 0;
    right: 50%;
  }
  20%, 100% {
    opacity: 1;
    right: 31%; // same as iphone + notif-margin
  }
}
@keyframes fadingAnimation {
  0%, 50%, 100% {
    opacity: 0;
  }
  10%, 40% {
    opacity: 1;
  }
}
@keyframes slidingTopAnimation {
  0%, 50%, 100% {
    transform: translateY(.8rem);
  }
  5%, 40% {
    transform: translateY(0);
  }
}
@keyframes rotatingAnimation {
  0%, 50%, 100% {
    transform: rotate(-160deg) scale(.5);
  }
  5%, 40% {
    transform: rotate(0) scale(1);
  }
}
@keyframes template3FirstSlideAnimation {
  0%, 100% {
    opacity: 0;
    transform: translateX(0);
  }
  5%, 20% {
    opacity: 1;
    transform: translateX(0);
  }
  25% {
    opacity: 0;
    transform: translateX(-6.4rem);
  }
}
@keyframes template3SecondSlideAnimation {
  0%, 25%, 50%, 100%{
    opacity: 0;
    transform: translateX(6.4rem);
  }
  30%, 45% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes template3ThirdSlideAnimation {
  0%, 50%, 75%, 100%{
    opacity: 0;
    transform: translateX(-6.4rem);
  }
  55%, 70% {
    opacity: 1;
    transform: translateX(0);
  }
  75% {
    opacity: 0;
    transform: translateX(-6.4rem);
  }
}
@keyframes template3FourthSlideAnimation {
  0%, 75% {
    opacity: 0;
    transform: translateX(6.4rem);
  }
  80%, 95% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(0);
  }
}
/* The typing effect for home-fan fan-reasons template-3 */
@keyframes writingAnimation {
  0%, 5%, 30%, 55%, 80%, 100% { width: 0; }
  // first slide field
  8% { width: 0; }
  9%, 10% { width: 1.3rem; }
  11%, 15% { width: 2.4rem; }
  16%, 17% { width: 3.6rem; }
  18%, 25% { width: 5rem; }
  // second slide field
  58% { width: 0; }
  59%, 62% { width: 1.3rem; }
  63%, 75% { width: 2.6rem; }
}

@keyframes scalingAnimation {
  from, to {transform: translateX(0);}
  10%, 20% {transform: translateX(1.6rem);}
  30% {transform: translateX(0);}
}

@keyframes movingAnimation {
  from, to { transform: translate(0, 0) scale(1); }
  25% { transform: translate(5%, 8%) scale(.9);}
  50% { transform: translate(10%, 10%) scale(.75); }
  75% { transform: translate(8%, 5%) scale(.9);}
}

@keyframes zoomingAnimation {
  25% { transform: translate(0, 0) scale(1); }
  100% { transform: translate(0, 0) scale(1.25); }
}

/* The typing effect, 1st text appearing during 4 seconds */
@keyframes typing-animation-1 {
  from { width: 0; height: auto;}
  33% { width: 100%; height: auto; }
  34% { width: 0; height: 0; }
  to { width: 0; height: 0; }
}
/* The typing effect, 2nd text appearing during 4 seconds */
@keyframes typing-animation-2 {
  from { width: 0; height: 0; }
  33% { width: 0; height: 0; }
  34% { width: 0; height: auto; }
  66% { width: 100%; height: auto; }
  67% { width: 0; height: 0; }
  to { width: 0; height: 0; }
}
/* The typing effect, 3rd text appearing during 4 seconds */
@keyframes typing-animation-3 {
  from { width: 0; height: 0; }
  66% { width: 0; height: 0; }
  67% { width: 0; height: auto; }
  to { width: 100%; height: auto; }
}
/* The typewriter cursor effect */
@keyframes blink-caret-animation {
  from, to { border-color: transparent }
  50% { border-color: var(--blink-caret-color); }
}

// carousel vertical
@keyframes slidingAnimation {
	0%    { transform: translateY(0); }
  // we substract 100% minus height of component
	100%  { transform: translateY(calc(-100% + (70vh - 7.5rem))); }
}
@keyframes slidingAnimationReverse {
  // we substract 100% minus height of component
	0%    { transform: translateY(calc(-100% + (70vh - 7.5rem))); }
	100%  { transform: translateY(0); }
}
@keyframes slidingAnimation100 {
	0%    { transform: translateY(0); }
  // we substract 100% minus height of component
	100%  { transform: translateY(calc(-100% + (100vh - 7.5rem))); }
}
@keyframes slidingAnimationReverse100 {
  // we substract 100% minus height of component
	0%    { transform: translateY(calc(-100% + (100vh - 7.5rem))); }
	100%  { transform: translateY(0); }
}

// carousel cards placeholder
@keyframes opacityAnimation {
  0%   { opacity: 1; transform: scale(.8); }
  50%  { opacity: .6; transform: scale(.6) }
  100% { opacity: 1; transform: scale(.8); }
}

// faq
@keyframes fadeOut {
  10%, 50% {
    background-color: rgba(0,0,0,.08);
    box-shadow: 0 0 2px 2px rgb(0,0,0,.08);
  }
  0%, 100% {
    background: transparent;
    box-shadow: 0 0 10px 10px transparent;
  }
}
