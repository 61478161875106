
.d-flex {
  display: flex;
  &.--centered {
    justify-content: center;
    align-items: center;
  }
  &.--vertical-centered {
    align-items: center;
  }
  &.--first-baseline {
    align-items: first baseline;
  }
  &.--horizontal-centered {
    justify-content: center;
  }
  &.--space-between {
    justify-content: space-between;
  }
  &.--column {
    flex-direction: column;
  }
  &.--row {
    flex-direction: row;
  }
  &.--wrap {
    flex-wrap: wrap;
  }
  &.--end {
    justify-content: end;
  }

  &.--rg{
    &-1{
      row-gap: $spacing-size-1;
    }
    &-2{
      row-gap: $spacing-size-2;
    }
    &-3{
      row-gap: $spacing-size-3;
    }
    &-4{
      row-gap: $spacing-size-4;
    }
    &-5{
      row-gap: $spacing-size-5;
    }
  }
  &.--cg{
    &-1{
      column-gap: $spacing-size-1;
    }
    &-2{
      column-gap: $spacing-size-2;
    }
    &-3{
      column-gap: $spacing-size-3;
    }
    &-4{
      column-gap: $spacing-size-4;
    }
    &-5{
      column-gap: $spacing-size-5;
    }
  }

  &.--a-{
    &s{
      align-items: flex-start;
    }
    &e{
      align-items: flex-end;
    }
  }

  &__a-{
    &c{
      align-self: center;
    }
  }
  &__g{
    flex-grow: 1;
  }

  &.--strech {
    align-items: stretch;
  }
}
