
mat-snack-bar-container.activity {
  --mdc-snackbar-container-color: white !important;
  --mdc-snackbar-supporting-text-color: $reelax-primary !important;

  .mat-mdc-snack-bar-action {
    color: $reelax-primary !important;
  }
}

.reelax-colors {
  background-color: $reelax-black !important;
  color: $reelax-white !important;
  .mat-mdc-snack-bar-action {
    color: $reelax-white !important;
  }
}
