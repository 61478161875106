.summary {
  @media screen and (min-width: $breakpoint-sm){
    margin: auto;
    max-width: 60rem;
  }
  &__card {
    margin: 2.4rem auto;
    text-align: left;
    box-shadow: none !important;
    &__bold-text {
      word-break: break-word;
      font-weight: 600;
      font-size: $font-size-16;
      line-height: 2rem;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: $font-size-18;
        line-height: 2.2rem;
      }
    }
    &__text {
      word-break: break-word;
      font-weight: 400;
      font-size: $font-size-14;
      line-height: 1.8rem;
      @media screen and (min-width: $breakpoint-lg) {
        font-size: $font-size-16;
        line-height: 2rem;
      }
    }
  }
}