.error-card {
  &__subtitle{
    width: 100%;
    @media screen and (min-width: $breakpoint-lg){
      text-align: center;
    }
  }
  &__column{
    text-align: center;
    width: 100%;
    background-color: $reelax-white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
    border-radius: .4rem;
    padding: 1.6rem;
    word-break: break-word;
    @media screen and (min-width: $breakpoint-lg){
      max-width: 50rem;
      margin: 0 auto;
    }
  }
}