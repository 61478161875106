/* truncate text */
@each $i in 1, 2, 3, 4, 5 {
  // Here we create multiple CSS classes at once : truncate-1, truncate-2, truncate-3, etc...
  .truncate-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

/* fonts families */
@import 'material-icons/iconfont/filled.css';
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('/assets/fonts/open-sans/OpenSans-Regular.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url('/assets/fonts/open-sans/OpenSans-SemiBold.ttf');
}
@font-face {
  font-family: 'Open Sans';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('/assets/fonts/open-sans/OpenSans-Bold.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Raleway Light'), local('Raleway-Light'), url('/assets/fonts/raleway/Raleway-Light.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Raleway Regular'), local('Raleway-Regular'), url('/assets/fonts/raleway/Raleway-Regular.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: local('Raleway Bold'), local('Raleway-Bold'), url('/assets/fonts/raleway/Raleway-Bold.ttf');
}

.mat-icon.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* size classes */
.fs-12{
  font-size: $font-size-12 !important;
}
.fs-14{
  font-size: $font-size-14 !important;
}
.fs-16{
  font-size: $font-size-16 !important;
}
.fs-18{
  font-size: $font-size-18 !important;
}
.fs-20{
  font-size: $font-size-20 !important;
}
.fs-24{
  font-size: $font-size-24 !important;
}
.fs-40{
  font-size: $font-size-40 !important;
}

/* size classes */
.fw-400{
  font-weight: 400 !important;
}
.fw-600{
  font-weight: 600 !important;
}
.fw-700{
  font-weight: 700 !important;
}

/* text align */
.text-left{
  text-align: left !important;
}
.center{
  text-align: center !important;
}
.text-right{
  text-align: right !important;
}

/* font style */
.italic {
  font-style: italic !important;
}

/* white spaces */
.ws-normal {
  white-space: normal;
}

/* word break */
.wb-bw {
  word-break: break-word;
}

/* Cursor */
.pointer {
  cursor: pointer;
}

/* links */
a:has(> button) {
  text-decoration: none;
}
.link {
  text-decoration: underline;
}
.tab-link, .full-tab-link {
  text-decoration: none;
  color: $reelax-dark-grey;
  &:hover, &.--active {
    color: $reelax-black;
  }
}
.full-tab-link {
  display: flex;
  align-items: center;
  &__icon {
    margin-right: .8rem;
  }
}
.full-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  &__text {
    margin-left: .8rem;
    text-decoration: underline;
  }
}
.link, .tab-link, .full-tab-link, .full-link {
  font-weight: 400;
  cursor: pointer;
}
.link, .full-link {
  font-weight: 700;
  /* colors */
  &.--danger {
    color: $reelax-danger;
  }
  &.--accent {
    color: $reelax-accent;
  }
  &.--primary {
    color: $reelax-primary;
  }
  &.--custom-event {
    color: var(--custom-event-color);
  }
}
/* icons */
.icon-text {
  display: flex;
  align-items: center;
  &__text {
    margin-left: .8rem;
  }
}

/* errors */
.error {
  color: $reelax-red;
  font-weight: 600;
  padding-bottom: .8rem;
  font-size: 1.4rem;
}

/* confirmation message */
.confirmation-message{
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.6rem;
  margin: 1.6rem 0;
  &.--center {
    justify-content: center;
  }
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 2rem;
  }
  &__icon {
    color: $reelax-green;
    margin-right: .8rem;
  }
}
