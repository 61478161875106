// override mat-expansion-panel-header
.mat-expansion-panel-header {
  height: 100% !important;
  padding: 1.2rem 2.4rem !important;
}

.panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 1.6rem;
  &__right-part{
    padding-left: .8rem;
    font-weight: 400;
  }
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 2rem;
  }
}
