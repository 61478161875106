.tickets-container{
  display: flex;
  flex-direction: column;
  @media screen and (min-width:$breakpoint-lg) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__tickets{
    margin-top: 1.4rem;
    width: 100%;
    max-width: 48rem;
    margin: auto;
    @media screen and (min-width: $breakpoint-lg) {
      width: 45%;
      margin: 1.4rem auto 0 auto;
    }
    &__ticket-details{
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      font-size: 1.6rem;
      &__number{
        font-weight: 600;
        width: 90%;
        margin-left: -.8rem;
        word-break: break-word;
      }
    }
  }
}

.ticket-div{
  overflow: hidden;
  margin-bottom: 1.6rem;
  padding-left: .8rem;
  margin-left: - .8rem;
  display: flex;
  flex-direction: row;
  margin-right: auto;
  background-color: var(--custom-event-color);
  color: var(--custom-text-color);
  border-radius: .4rem;
  max-width: inherit;
  &.--error {
    color: var(--custom-error-color);
  }
  &__info{
    padding: .8rem .8rem .8rem 0;
    width: 80%;

    // override mat-form-field children elements for tickets div
    label, span{
      color: var(--custom-text-color) !important;
    }
    .mat-mdc-input-element, .mat-input-element::selection{
      color: var(--custom-text-color) !important;
    }
    input{
      caret-color: var(--custom-text-color) !important;
    }
    .mdc-line-ripple::before, .mdc-line-ripple::after {
      border-bottom-color: var(--custom-text-color) !important;
    }
    .mat-mdc-form-field-infix{
      width: 100%;
      min-width: 5rem;
    }
  }
  &__style{
    width: 20%;
    border-left: .4rem dashed var(--custom-background-color); 
    display: flex;
    align-items: center;
    position: relative;
    &__circle{
      border-radius: 50%;
      background-color: var(--custom-background-color);
      margin-left: 100%;
      height: 40%;
      width: 4rem;
      margin-right: -2rem; // width of circle divided by 2 to display only half of the circle inside div
      margin-left: auto;
      &.--high {
        height: 35%;
        width: 6rem;
        margin-right: -3rem; // width of circle divided by 2 to display only half of the circle inside div
      }
    }
  }
}

// New version of sale process
.sale-ticket {
  background-color: $reelax-white;
  overflow: hidden;
  margin-bottom: 1.6rem;
  padding-left: .8rem;
  margin-right: auto;
  border-radius: .4rem;
  max-width: inherit;
  &__info{
    padding: .8rem .8rem .8rem 0;
    width: 80%;
  }
  &__style{
    width: 20%;
    border-left: .4rem dashed var(--custom-background-color); 
    display: flex;
    align-items: center;
    position: relative;
    &__circle{
      border-radius: 50%;
      background-color: var(--custom-background-color);
      margin-left: 100%;
      height: 40%;
      width: 4rem;
      margin-right: -2rem; // width of circle divided by 2 to display only half of the circle inside div
      margin-left: auto;
      @media screen and (min-width: $breakpoint-sm) {
        height: 35%;
        width: 6rem;
        margin-right: -3rem; // width of circle divided by 2 to display only half of the circle inside div
      }
      
    }
  }
}