.toggle {
  display: flex;
  align-items: center;
  mat-slide-toggle {
    margin-right: .4rem;
  }
  font-size: 1.6rem;

  &.--small {
    font-size: 1.4rem;
  }
}
