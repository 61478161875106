.buttons-group {
  display: flex;
  margin: .8rem 0;
  gap: .8rem;
  flex-wrap: wrap;
  align-items: stretch;
  position: relative;
  z-index: 10;
  &.--row {
    width: 100%;
  }
  &.--centered {
    justify-content: center;
    align-self: center;
  }
  &.--full-width {
    a, button{
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    }
    gap: $spacing-size-3;
  }
  &.--full-width-on-small {
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
    a, button{
      // on extra-small screens only
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
  }
}

.button {
  cursor: pointer;
  letter-spacing: normal !important;
  border-radius: .4rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
  .mdc-button__label {
    font-size: 1.6rem ;
    line-height: 2rem ;
    font-weight: 800 ;
    @media screen and (min-width: $breakpoint-md) {
      font-size: 2rem;
    }
  }
  &.mdc-button, &.mat-mdc-button.mat-mdc-button-base {
    height: unset;
  }
  padding: .8rem !important;
  width: auto;
  min-height: 4rem;
  white-space: normal !important;
  transition: all .2s;
  font-family: $accent-font-family !important;
  font-family: var(--custom-accent-font-family) !important;
  & > *, & > * > :not(mat-icon) {
    font-family: $accent-font-family;
    font-family: var(--custom-accent-font-family);
  }
  @media screen and (min-width: $breakpoint-md) {
    min-height: 5.6rem;        
  }
  &:not([disabled]):hover {
    &::after {
      filter: blur(6px);
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      border-radius: .4rem;
      background: $reelax-gradient;
    }
  }
  /* size */
  &.--x-small {
    .mdc-button__label {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
    &.mdc-button {
      min-height: unset;
      min-width: unset;
    }
  }
  &.--small {
    .mdc-button__label {
      font-size: 1.4rem;
      line-height: 1.8rem;
      @media screen and (min-width: $breakpoint-md) {
        font-size: 1.6rem;
        line-height: 2rem;
      } 
    }
    &.mdc-button {
      min-height: unset; 
    }
  }
  &.--large {
    &.mdc-button {
      min-width: 11.2rem;
      @media screen and (min-width: $breakpoint-md) {
        min-width: 27.1rem;
      }
    }
  }
  &.--full-width {
    width: 100%;
  }
  &.--xlarge {
    &.mdc-button {
      min-height: 5.6rem;
    }
    .mdc-button__label {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    &.--widget {
      @media screen and (max-width: 400px) {
        .mdc-button__label {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }
    }
  }
  /* colors */
  &.--multicolor {
    border: unset;
    background: $reelax-gradient;
    color: $reelax-white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .16);
  }
  &.--outlined-multicolor {
    border: .1rem solid transparent;
    background: $reelax-gradient;
    background-clip: text;
    color: transparent;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -.1rem;
      border-radius: .4rem;
      background: $reelax-gradient;
    }
    &:after {
      content: '';
      position: absolute;
      border-radius: .4rem;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $reelax-white;
      z-index: -1;
    }
  }
  &.--primary {
    border: .1rem solid $reelax-primary;
    background-color: $reelax-primary;
    .mdc-button__label, .material-icons {
      color: $reelax-white;
    }
  }
  &.--outlined-primary {
    border: .1rem solid $reelax-primary;
    background-color: $reelax-white;
    .mdc-button__label, .material-icons {
      color: $reelax-primary;
    }
  }
  &.--accent {
    border: .1rem solid $reelax-accent;
    background-color: $reelax-accent;
    .mdc-button__label, .material-icons {
      color: $reelax-white;
    }
  }
  &.--outlined-accent {
    border: .1rem solid $reelax-accent;
    background-color: $reelax-white;
    .mdc-button__label, .material-icons {
      color: $reelax-accent;
    }
  }
  &.--danger {
    border: .1rem solid $reelax-danger;
    background-color: $reelax-danger;
    .mdc-button__label, .material-icons {
      color: $reelax-white;
    }
  }
  &.--outlined-danger {
    border: .1rem solid $reelax-danger;
    background-color: $reelax-white;
    .mdc-button__label, .material-icons {
      color: $reelax-danger;
    }
  }
  &.--custom-event {
    border: .1rem solid var(--custom-event-color);
    background-color: var(--custom-event-color);
    .mdc-button__label, .material-icons {
      color: var(--custom-text-color);
    }
  }
  &.--outlined-custom-event {
    border: .1rem solid var(--custom-event-color);
    background-color: var(--custom-text-color);
    .mdc-button__label, .material-icons {
      color: var(--custom-event-color);
    }
  }
  /* disabled */
  &.mat-mdc-button[disabled] {
    cursor: not-allowed;
    pointer-events: auto;
    opacity: .8;
  }
  &__icon{
    margin-right: .8rem;
  }
  & .material-icons.mat-icon {
    font-size: 1.5em ;
    height: 1em ;
    width: 1em ;
  }
}
a.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mat-mdc-button {
  text-align: center;
  letter-spacing: normal !important;
  & .material-icons.mat-icon { 
    font-size: 1.5em ;
    height: 1em ;
    width: 1em ;
  }
}
