// leaflet map style needs to be in global css file
.leaflet-tooltip.map-tooltip {
  font-size: 1.2rem;
  font-weight: 600;
  padding-top: 0.2rem;
  padding-right: 0.4rem;
  padding-bottom: 0.2rem;
  padding-left: 0.4rem;
  border: 0;
  line-height: 1.2;

  .map-tooltip__content {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 20rem;
  }
}
.leaflet-tooltip-left.map-tooltip {
  margin-left: -0.6rem;
}
.leaflet-tooltip-right.map-tooltip {
  margin-right: -0.6rem;
}
.leaflet-marker-pane {
  z-index: 660;
}

.map-cluster {
	background-clip: padding-box;
  background-color: rgba($reelax-primary, 0.4);

  & div {
    text-align: center;
    background-color: rgba($reelax-primary, 0.6);
  }
  & span {
    color: $reelax-white;
  }
  @each $i in 2, 3, 4, 5, 6 {
    // Here we create multiple CSS classes at once : --s2, --s3,  etc
    &.--s#{$i} {
      border-radius: calc(1.5rem*(1 + $i/6)) !important;
      width: calc(3rem*(1 + $i/6)) !important;
      height: calc(3rem*(1 + $i/6)) !important;
      & div {
        border-radius: calc(1.25rem*(1 + $i/6)) !important;
        width: calc(2.20rem*(1 + $i/6));
        height: calc(2.20rem*(1 + $i/6));
        margin-left: calc(0.40rem*(1 + $i/6));
        margin-top: calc(0.40rem*(1 + $i/6));
      }
      & span {
        line-height: calc(2.20rem*(1 + $i/6));
        font-size: calc(1.5rem*(1 + $i/6));
      }
    }
  }

  &.--active {
    background-color: $reelax-black;
  }
  &.--active div {
    background: $reelax-gradient;
  }

}
