.wallet-card {
  box-shadow: none !important;
  &__content {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between !important;
    gap: .8rem;
    flex-wrap: wrap;
    @media screen and (min-width: $breakpoint-sm) {
      gap: 1.6rem;
      flex-wrap: nowrap;
    }
    &__event {
      font-size: $font-size-14;
      width: 100%;
      @media screen and (min-width: $breakpoint-sm) {
        width: inherit;
      }
      @media screen and (min-width: $breakpoint-lg) {
        font-size: $font-size-16;
      }
    }
    &__price {
      font-weight: 700;
      white-space: nowrap;
      font-size: $font-size-14;
      margin-right: auto;
      @media screen and (min-width: $breakpoint-sm) {
        margin-left: auto;
        margin-right: unset;
      }
      @media screen and (min-width: $breakpoint-lg) {
        font-size: $font-size-16;
      }
    }
  }
 
}