td.mat-mdc-cell, td.mat-mdc-footer-cell, th.mat-mdc-header-cell {
  padding: .5rem !important;
}
table {
  width: 100%;
}
th.mat-mdc-header-cell {
  color: #0000008a;
  font-size: $font-size-12;
}

tr.expanding-row {
  height: 0 !important;
  background: $reelax-light-grey;

  td:first-child {
    border-left: 4px solid;
    border-left-color: var(--custom-event-color);
  }
  td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.header-cell {
  text-align: left;
}

tr.mdc-data-table__row:last-child td.mat-mdc-cell {
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid !important;
}

tr.classic-row {
  cursor: pointer;
  & td {
    border-bottom-width: 0;
  }
  & .expanding-row {
    td:first-child {
      border-left: 4px solid;
      border-left-color: var(--custom-event-color);
    }
  }
  &:not(.expanding-row):hover {
    background: $light-reelax-gradient;
  }
  &:not(.expanding-row):active {
    background: $reelax-light-grey;
  }
}

.expanding-detail {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
