@import '../variables';

/* color classes */
.reelax-black{
  color: $reelax-black !important;
}
.reelax-green{
  color: $reelax-green !important;
}
.reelax-blue{
  color: $reelax-blue !important;
}
.reelax-purple{
  color: $reelax-purple !important;
}
.reelax-orange{
  color: $reelax-orange !important;
}
.reelax-yellow{
  color: $reelax-yellow !important;
}
.reelax-white{
  color: $reelax-white !important;
}
.reelax-light-grey{
  color: $reelax-light-grey !important;
}
.reelax-dark-grey{
  color: $reelax-dark-grey !important;
}
.reelax-red{
  color: $reelax-red !important;
}
.green{
  color: $green !important;
}
.orange{
  color: $orange !important;
}
.red{
  color: $red !important;
}
.sanjuan{
  color: $sanjuan !important;
}
.custom-event{
  color: var(--custom-event-color) !important;
}
.custom-event-text {
  color: var(--custom-text-color);
}
.reelax-gradient{
  color: transparent;
  background: $reelax-gradient;
  background-clip: text;
}

/* background-color classe */
.reelax-black-bg{
  background-color: $reelax-black !important;
}
.reelax-green-bg{
  background-color: $reelax-green !important;
}
.reelax-blue-bg{
  background-color: $reelax-blue !important;
}
.reelax-purple-bg{
  background-color: $reelax-purple !important;
}
.reelax-orange-bg{
  background-color: $reelax-orange !important;
}
.reelax-yellow-bg{
  background-color: $reelax-yellow !important;
}
.reelax-white-bg{
  background-color: $reelax-white !important;
}
.reelax-light-grey-bg{
  background-color: $reelax-light-grey !important;
}
.reelax-dark-grey-bg{
  background-color: $reelax-dark-grey !important;
}
.reelax-red-bg{
  background-color: $reelax-red !important;
}
.custom-event-bg{
  background-color: var(--custom-event-color) !important;
}
.reelax-gradient-bg{
  background: $reelax-gradient;
}
.light-reelax-gradient-bg{
  background: $light-reelax-gradient;
}

// border
.custom-border-left {
  border-left: .4rem solid var(--custom-event-color) !important;
}

.custom-background-color {
  background-color: var(--custom-background-color) !important;
}

.custom-element-on-background-color {
  color: var(--custom-element-on-background-color) !important;

  // for mat form on backgrund
  .mat-mdc-floating-label,
  .mat-focused .mat-form-field-label,
  .mat-mdc-select-value-text,
  .mat-mdc-select-arrow,
  .mat-hint
  {
    color: var(--custom-element-on-background-color) !important;
  }
  .mat-focused .mat-mdc-floating-label {
    color: var(--custom-element-on-background-color) !important;
  }
  .mat-mdc-form-field-hint {
    color: var(--custom-element-on-background-color) !important;
  }
  .mdc-line-ripple::before {
    border-bottom-color: var(--custom-element-on-background-color) !important;

  }


}