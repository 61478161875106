.text {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
  @media screen and (min-width: $breakpoint-md) {
    font-size: 2rem;
    margin-bottom: 2.4rem;
  }

  &.--normal {
    font-size: 1.4rem;
    @media screen and (min-width: $breakpoint-md) {
      font-size: 1.6rem;
    }
  }

  &.--start {
    text-align: start
  }

}

.legend {
  display: flex;
  align-items: center;
  color: $reelax-dark-grey;
  font-size: 1.2rem;
  font-weight: 400;
}

.font-size-inherit {
  font-size: inherit !important;
  height: auto !important;
  width: auto !important;
  vertical-align: middle;
  line-height: inherit;
}

.cat-group-name {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  word-break: break-word;
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
