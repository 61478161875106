/* You can add global styles to this file, and also import other style files */
@import "./reelax-librairies/colors"; // must be imported first
@import "./reelax-librairies/animations";
@import "./reelax-librairies/breakpoints";
@import "./reelax-librairies/fonts";
@import "./reelax-librairies/scroll";
@import "./reelax-librairies/spacing";
@import "./reelax-librairies/backgrounds";
@import "./reelax-librairies/flexbox";

// Components
@import './components/autocomplete.scss';
@import './components/button';
@import './components/card';
@import './components/dashboard-card';
@import './components/checkbigbox';
@import './components/checkbox';
@import './components/confirmation';
@import './components/dialog';
@import './components/drawer';
@import './components/error-card';
@import './components/expansion-panel';
@import './components/facing-cards';
@import './components/flag';
@import './components/form';
@import './components/heading';
@import './components/menu';
@import './components/notification';
@import './components/stepper';
@import './components/tab';
@import './components/table';
@import './components/text';
@import './components/ticket';
@import './components/toggle';
@import './components/triangle-separator';
@import './components/typing';
@import './components/summary-card';
@import './components/account-infos';
@import './components/static-chip';
@import './components/wallet-card';
@import './components/widget';
@import './components/leaflet.scss';
@import './components/dashboard-form';
@import './components/wl-card.scss';
@import './components/ticketing-platform.scss';
@import './components/selectable.scss';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: $primary-font-family;
  font-family: var(--custom-primary-font-family);
}

html {
  font-size: 62.5%; /* define rem setting */
  background-color: var(--custom-background-color);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root { 
  --custom-event-color: #{$reelax-primary};
  --custom-text-color: #{$reelax-white};
  --custom-background-color: #{$reelax-light-grey};
  --custom-element-on-background-color: #{$reelax-primary};
  --custom-error-color: #{$reelax-danger};
  --custom-primary-font-family: #{$primary-font-family}; 
  --custom-accent-font-family: #{$accent-font-family}; 
}

.page-container {
  margin-left: auto; /* property that will be kept for upper widths as expected */  
  margin-right: auto; /* property that will be kept for upper widths as expected */ 
  &:last-child {
    margin-bottom: 3.2rem;
  }
  width: calc(100% - 2.1rem);
  @media screen and (min-width: $breakpoint-sm) {
    width: $sm-width;
  }
  @media screen and (min-width: $breakpoint-md) {
    width: $md-width;
  }
  @media screen and (min-width: $breakpoint-lg) {
    width: $lg-width;
  }
  @media screen and (min-width: $breakpoint-xl) {
    width: $xl-width;
  }

  &.--no-margin-bottom {
    margin-bottom: 0rem;
  }

  &.--dashboard {
    width: auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    @media screen and (min-width: $breakpoint-md) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    &.--with-width {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      @media screen and (min-width: $breakpoint-md) {
        margin-left: auto;
        margin-right: auto;
      }
      .--close & {
        @media screen and (min-width: $breakpoint-sm) {
          width: $dashboard-collapsed-sm-width !important;
        }
        @media screen and (min-width: $breakpoint-md) {
          width: $dashboard-collapsed-md-width !important;
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: $dashboard-collapsed-lg-width !important;
        }
        @media screen and (min-width: $breakpoint-xl) {
          width: $dashboard-collapsed-xl-width !important;
        }
      }
      .--open & {
        @media screen and (min-width: $breakpoint-sm) {
          width: $dashboard-opened-sm-width !important;
        }
        @media screen and (min-width: $breakpoint-md) {
          width: $dashboard-opened-md-width !important;
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: $dashboard-opened-lg-width !important;
        }
        @media screen and (min-width: $breakpoint-xl) {
          width: $dashboard-opened-xl-width !important;
        }
      }
    }
  }
}

.small-container {
  max-width: 60rem;
  padding: 0 .8rem;
  margin: auto;
}

.d-inline-flex {
  display: inline-flex;
}

.overflow-hidden {
  overflow: hidden;
}

.blurred-panel {
  filter: blur(1rem);
  transition: all .5s;
}

.hidden-data {
  // at first, only filter property was applied but not enough secure as we can rebuild image
  // filter: blur(.2rem);
  width: 8ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.custom-color-svg {
  pointer-events: none; // allow click parent component
  // TODO : move this css class somewhere else
  animation: appearingAnimation ease-in 1s;
}

mat-icon {
  overflow: unset !important; // fix https://github.com/angular/components/issues/25101
}

mat-progress-bar {
  text-align: left; // fix https://github.com/angular/components/issues/27613
  border-radius: .4rem;
  &.--green{
    --mdc-linear-progress-active-indicator-color: #{$reelax-green};
  }
  &.--orange{
    --mdc-linear-progress-active-indicator-color: #{$reelax-orange};
  }
  &.--red{
    --mdc-linear-progress-active-indicator-color: #{$reelax-red};
  }
}

.mat-mdc-optgroup.hideLabel .mat-mdc-optgroup-label {
  display: none;
}
