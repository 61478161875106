.drawer{
  height: calc(100vh - 7.5rem); // 7.5rem is height of header
}

mat-drawer {
  width: 5.2rem;
  transition: width 0.15s ease-in-out;
  &.--open{
    width: 24rem;
  }
}

.mat-drawer-content {
  margin-left: 0rem !important;
  transition: margin-left 0.15s ease-in-out;
  &.--close{
    margin-left: 5.2rem !important;
  }
  &.--open{
    margin-left: 24rem !important;
  }
}

::ng-deep .small-page-container .page-container {
  width: calc(100% - 2.1rem - $dashboard-collapsed-width);
  @media screen and (min-width: $breakpoint-sm) {
    width: $dashboard-collapsed-sm-width !important;
  }
  @media screen and (min-width: $breakpoint-md) {
    width: $dashboard-collapsed-md-width !important;
  }
  @media screen and (min-width: $breakpoint-lg) {
    width: $dashboard-collapsed-lg-width !important;
  }
  @media screen and (min-width: $breakpoint-xl) {
    width: $dashboard-collapsed-xl-width !important;
  }
}

::ng-deep .medium-page-container .page-container {
  width: calc(100% - 2.1rem - $dashboard-opened-width);
  @media screen and (min-width: $breakpoint-sm) {
    width: $dashboard-opened-sm-width !important;
  }
  @media screen and (min-width: $breakpoint-md) {
    width: $dashboard-opened-md-width !important;
  }
  @media screen and (min-width: $breakpoint-lg) {
    width: $dashboard-opened-lg-width !important;
  }
  @media screen and (min-width: $breakpoint-xl) {
    width: $dashboard-opened-xl-width !important;
  }
}

::ng-deep .mat-drawer-inner-container {
  background-color: unset !important;
}
