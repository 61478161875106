@use '@angular/material' as mat;
@import "./variables";

/* reelax material palettes */
$reelax-palette-black: (
  100: $reelax-black,
  200: $reelax-black,
  300: $reelax-black,
  400: $reelax-black,
  500: $reelax-black,
  600: $reelax-black,
  700: $reelax-black,
  800: $reelax-black,
  900: $reelax-black,
  A100: $reelax-black,
  A200: $reelax-black,
  A400: $reelax-black,
  A700: $reelax-black,
  contrast: (
    100: $reelax-white,
    200: $reelax-white,
    300: $reelax-white,
    400: $reelax-white,
    500: $reelax-white,
    600: $reelax-white,
    700: $reelax-white,
    800: $reelax-white,
    900: $reelax-white,
    A100: $reelax-white,
    A200: $reelax-white,
    A400: $reelax-white,
    A700: $reelax-white,
  )
);
$reelax-palette-custom-event: (
  100: var(--custom-event-color),
  200: var(--custom-event-color),
  300: var(--custom-event-color),
  400: var(--custom-event-color),
  500: var(--custom-event-color),
  600: var(--custom-event-color),
  700: var(--custom-event-color),
  800: var(--custom-event-color),
  900: var(--custom-event-color),
  A100: var(--custom-event-color),
  A200: var(--custom-event-color),
  A400: var(--custom-event-color),
  A700: var(--custom-event-color),
  contrast: (
    100: $reelax-white,
    200: $reelax-white,
    300: $reelax-white,
    400: $reelax-white,
    500: $reelax-white,
    600: $reelax-white,
    700: $reelax-white,
    800: $reelax-white,
    900: $reelax-white,
    A100: $reelax-white,
    A200: $reelax-white,
    A400: $reelax-white,
    A700: $reelax-white,
  )
);
$reelax-palette-red: (
  100: $reelax-red,
  200: $reelax-red,
  300: $reelax-red,
  400: $reelax-red,
  500: $reelax-red,
  600: $reelax-red,
  700: $reelax-red,
  800: $reelax-red,
  900: $reelax-red,
  A100: $reelax-red,
  A200: $reelax-red,
  A400: $reelax-red,
  A700: $reelax-red,
  contrast: (
    100: $reelax-white,
    200: $reelax-white,
    300: $reelax-white,
    400: $reelax-white,
    500: $reelax-white,
    600: $reelax-white,
    700: $reelax-white,
    800: $reelax-white,
    900: $reelax-white,
    A100: $reelax-white,
    A200: $reelax-white,
    A400: $reelax-white,
    A700: $reelax-white,
  )
);

// Include non-theme styles for core.
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define your application's custom theme.
$primary: mat.m2-define-palette($reelax-palette-black);
$accent: mat.m2-define-palette($reelax-palette-custom-event);
$warn: mat.m2-define-palette($reelax-palette-red);
$custom-theme: mat.m2-define-light-theme($primary, $accent, $warn);

// Include theme styles for Angular Material components.
@include mat.all-component-themes($custom-theme);
