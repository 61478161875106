
.ticketing-logo {
  display: flex;
  align-items: center;
  border-radius: .4rem;
  height: inherit;
  width: fit-content;
  padding: .4rem;
  margin: auto 0;

  &.--Yurplan {
    background-color: #f84904;
  }
  &.--Weezevent {
    background-color: #0032fa;
  }
  &.--Billetweb {
    background-color: #151756;
  }
  &.--Festik {
    background-color: #F7213C;
  }
  &.--Wilout {
    background-color: #2e3757;
  }
  &.--Rodrigue {
    background-color: #D04014;
  }
  &.--SoTicket {
    background-color: #F7EA20;
  }
  &.--Reelax {
    background: $reelax-gradient;
  }

  img, .img {
    height: 100%;
    width: auto;
    max-width: 8rem;
    max-height: 4rem;
  }
}
