
.static-chip {
  display: inline;
  padding: 0.5rem 1.5rem;
  border-radius: 2.5rem;
  background-color: $reelax-light-grey;
  font-weight: 600;
  white-space: nowrap;

  &.--green {
    color:  $reelax-color-1;
    background-color: lighten($reelax-color-1, 40%);
  }
  &.--orange {
    color:  $reelax-color-4;
    background-color:lighten($reelax-color-4, 20%);
  }
  &.--yellow {
    color:  $reelax-color-5;
    background-color: lighten($reelax-color-5, 25%);
  }

}