
.triangle-separator{
  position: relative;
  height: 10vw;
  overflow: hidden;
  z-index: 1;
  &:after {
    position: absolute;
    content: '';
    background-color: $reelax-white;
    height: 100%;
    width: 100%;
    transform: rotate(-2deg);
    top: 50%;
  }
  &.--no-height {
    margin-top: -10vw;
  }

  &.--reverse:after {
    top: unset;
    bottom: 50%;
  }

  &.--reverse {
    &.--no-height {
      margin-top: 0;
      margin-bottom: -10vw;
    }
  } 

}
