
.selectable {
  -webkit-touch-callout: all; /* iOS Safari */
  -webkit-user-select: all; /* Safari */
  -khtml-user-select: all; /* Konqueror HTML */
  -moz-user-select: all; /* Firefox */
  -ms-user-select: all; /* Internet Explorer/Edge */
  user-select: all; /* Chrome and Opera */

}
