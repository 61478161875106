/* breakpoints */
$breakpoint-xs: 360px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;

/* minimal width */
$margin-mini: 30px;
$sm-width: $breakpoint-sm - $margin-mini;
$md-width: $breakpoint-md - $margin-mini;
$lg-width: $breakpoint-lg - $margin-mini;
$xl-width: $breakpoint-xl - $margin-mini;

$dashboard-collapsed-width: 60px;
$dashboard-collapsed-sm-width: $sm-width - $dashboard-collapsed-width;
$dashboard-collapsed-md-width: $md-width - $dashboard-collapsed-width;
$dashboard-collapsed-lg-width: $lg-width - $dashboard-collapsed-width;
$dashboard-collapsed-xl-width: $xl-width - $dashboard-collapsed-width;

$dashboard-opened-width: 240px;
$dashboard-opened-sm-width: $sm-width - $dashboard-opened-width;
$dashboard-opened-md-width: $md-width - $dashboard-opened-width;
$dashboard-opened-lg-width: $lg-width - $dashboard-opened-width;
$dashboard-opened-xl-width: $xl-width - $dashboard-opened-width;

/* colors */
$reelax-black: #242425;
$reelax-white: #FFFFFF;
$reelax-light-grey: #F4F4F4;
$reelax-dark-grey: #646473;
$reelax-red: #A30B37;
$green: #43cea2;
$orange: #ce7d43;
$red: #ce4358;
$sanjuan: #415A68;

$reelax-green: #3bceac;
$reelax-blue: #4cc9f0;
$reelax-purple: #c77dff;
$reelax-orange: #ff9b85;
$reelax-yellow: #ffcb69;
$reelax-color-1: $reelax-green;
$reelax-color-2: $reelax-blue;
$reelax-color-3: $reelax-purple;
$reelax-color-4: $reelax-orange;
$reelax-color-5: $reelax-yellow;
$reelax-gradient: linear-gradient(60deg, $reelax-color-1, $reelax-color-2, $reelax-color-3, $reelax-color-4, $reelax-color-5);
$gradient-opacity: .9;
$light-reelax-gradient: linear-gradient(60deg, transparentize($reelax-color-1, $gradient-opacity), transparentize($reelax-color-2, $gradient-opacity), transparentize($reelax-color-3, $gradient-opacity), transparentize($reelax-color-4, $gradient-opacity), transparentize($reelax-color-5, $gradient-opacity));
$reelax-gradient-vertical: linear-gradient(0deg, $reelax-color-1, $reelax-color-2, $reelax-color-3, $reelax-color-4, $reelax-color-5);
$reelax-primary: $reelax-black;
$reelax-accent: $reelax-purple;
$reelax-danger: $reelax-red;

/* spacing */
$spacing-size-0: 0;
$spacing-size-1: .2rem;
$spacing-size-2: .4rem;
$spacing-size-3: .8rem;
$spacing-size-4: 1.6rem;
$spacing-size-5: 3.2rem;

/* font sizes */
$font-size-12: 1.2rem;
$font-size-14: 1.4rem;
$font-size-16: 1.6rem;
$font-size-18: 1.8rem;
$font-size-20: 2rem;
$font-size-24: 2.4rem;
$font-size-40: 4rem;

$font-size-caption: $font-size-12;

/* font family */
$primary-font-family: 'Open Sans';
$accent-font-family: 'Raleway';

/* common */
$header_height: 7.5rem;
