.dialog{
  padding: $spacing-size-2;
  &-content{
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 $spacing-size-3;
    &__row{
      width: 100%;
      font-weight: 400;
      font-size: 1.6rem;
      @media screen and (min-width: $breakpoint-lg){
        font-size: 2rem;
      }
      margin-top: $spacing-size-2;
      margin-bottom: $spacing-size-4;
    }
    &__text{
      font-size: 1.4rem;
      font-weight: 400;
      @media screen and (min-width: $breakpoint-lg){
        font-size: 1.6rem;
      }
    }
    &__img{
      width: 100%;
      text-align: center;
      padding: .8rem 0;
    }
  }
}

.close-icon{
  float: right;
  font-size: 2.4rem !important;
  cursor: pointer;
}

.dialog-title{
  clear: both;
  text-align: center;
  font-family: $primary-font-family !important;
  font-family: var(--custom-primary-font-family) !important;
  font-weight: 700 !important;
  font-size: 2rem;
  @media screen and (min-width: $breakpoint-lg){
    font-size: 2.4rem !important;
  }
}

.dialog-subtitle{
  font-weight: 400;
  font-size: 1.6rem;
  @media screen and (min-width: $breakpoint-lg){
    font-size: 2rem;
  }
}

.tickets-info{
  display: flex;
  justify-content: space-around;
  &__details{
    font-size: 1.6rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    &__identity{
      margin-top: 1.6rem;
      font-weight: 600;
    }
  }
}

.mat-mdc-dialog-surface {
  padding: 0.8rem ;
  @media screen and (min-width: $breakpoint-lg){
    padding: 2.4rem ;
  }
}

.mat-mdc-dialog-content {
  color: rgba(0, 0, 0, 0.87) !important;
  letter-spacing: normal !important;
  max-height: 70vh !important;
  padding: 0 0.8rem !important;
  @media screen and (min-width: $breakpoint-lg){
    padding: 0 2.4rem !important;
  }
}

.mat-mdc-dialog-title {
  margin-bottom: 2rem !important;
}

// Img for seating plan
.seating-plan-img {
  & .img-container {
    width: auto;
    margin: auto;
    overflow: scroll;
    img {
      max-height: 50vh;
      transform-origin: 0 0;
    }
  }
 
}
