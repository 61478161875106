.card-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &__card {
    margin: 0 auto;
    width: 100%;
    max-width: 38rem;
    @media screen and (min-width: $breakpoint-sm) {
      margin: 0;
      width: 50%;
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin: 0;
      width: 33%;
    }
  }
}

mat-card {
  font-family: inherit !important;
}
