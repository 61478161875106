.strip {
  background-color: var( --custom-event-color);
  color: var(--custom-text-color);
  min-height: $header_height;
  position: relative;
  display: flex;

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 4rem auto .8rem auto;;
    font-size: 2rem !important;

    @media screen and (min-width: $breakpoint-md) {
      margin: 0 auto;
    }
  }

  &__back-button {
    position: absolute;
    top: .4rem;
    left: .4rem;
  }

  &__icon-tabs {
    position: absolute;
    top: .4rem;
    right: .4rem;
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  .tab-link {
    background-color: var( --custom-event-color);
    color: var(--custom-text-color);
  }
}
