
.dashboard-card-grid{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1.6rem;
  gap: .8rem;
  > :empty {
    display: none;
  }
  > * {
    gap: .8rem;
    @media screen and (max-width: $breakpoint-sm) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  .full-width {
    flex: 1;
  }
}

.dashboard-card-grid-v2 {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  margin-top: 1.6rem;
  gap: 3rem;
  > :empty {
    display: none;
  }

  @media screen and (max-width: $breakpoint-lg) {
    gap: 1rem;
    flex-direction: column;
  }
  
}

.dashboard-card {
  width: 36rem;
  height: 21rem;
  overflow: clip;
  @media screen and (max-width: $breakpoint-lg) {
    width: 100% !important;
    min-height: 16rem;
    height: auto;
  }
  &.--double {
    width: 75rem; // 36 * 2 + gap
    @media screen and (max-width: $breakpoint-lg) {
      width: 100% !important;
      flex-direction: column;
      height: auto;
    }
  }
  &.--triple {
    width: 114rem; // 36 * 3 + gap * 2
    @media screen and (max-width: $breakpoint-xxl) {
      width: 100% !important;
      flex-direction: column;
      height: auto;
    }
  }
  &.--full-height {
    height: 100%;
  }

  &.--full-width {
    width: 100%;
  }
}

.section-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
  height: 100%;
}

.action-buttons {
  justify-content: end;
  @media screen and (min-width: $breakpoint-lg) {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  [mat-button] {
    min-height: unset;
    .mdc-button__label {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
  }
}

.card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__text {
    font-weight: 600;
    font-size: 2.0rem;
    @media screen and (min-width: $breakpoint-lg) {
      font-size: 2.4rem;
    }
  }
  &__img {
    opacity: .3;
    background: $reelax-gradient;
    background-clip: text;
    color: transparent;
  }
  &__img.mat-icon.material-icons, &__img {
    height: 5rem;
    width: 5rem;
    font-size: 5rem;
  }
}

.circle-border {
  $div-height: 7.2rem;
  $border-thickness: .8rem;
  border: $border-thickness solid var(--custom-event-color);
  border-bottom: 0;
  border-radius: ($div-height + $border-thickness) ($div-height + $border-thickness) 0 0;
  width: ($div-height * 2);
  height: $div-height;
  text-align: center;
  padding: 3.2rem .8rem 0 .8rem;
  font-size: 2rem;
  font-weight: 600;
  @media screen and (min-width: $breakpoint-md) {
    font-size: 2.4rem;
  }
}

.stats {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.2rem;
  gap: 1.2rem;
  &.--small {
    .stats__element {
      color: $reelax-dark-grey;
      &__nb {
        font-size: 1.6rem;
        @media screen and (min-width: $breakpoint-md) {
          font-size: 1.8rem;
        }
      }
      &__name {
        font-size: 1.2rem;
      }
    }
  }
  &__element {
    gap: .8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__nb {
      font-size: 2rem;
      font-weight: 600;
      white-space: nowrap;
      @media screen and (min-width: $breakpoint-md) {
        font-size: 2.4rem;
      }
      &__total {
        color: $reelax-dark-grey;
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
    &__name {
      font-size: 1.4rem;
      font-weight: 400;
      display: flex;
      align-items: center;
    }
  }
}

.data-container {
  padding-right: 0;
  padding-left: 0;
  @media screen and (min-width: $breakpoint-md) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
