.wl-cards {
  display: flex;
  flex-direction: column;
  gap: $spacing-size-4;
  @media screen and (min-width: $breakpoint-lg) {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .wl-cards__card  {
    flex: 1 1 0;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    gap: $spacing-size-2;
    justify-content: flex-start;
    &.--wl-settings {
      text-align: left;
      border-left-width: .4rem;
      border-left-style: solid;
    }
    @media screen and (min-width: $breakpoint-lg) {
      min-width: calc(33% - 4.8rem);
    }
    .large-icon {
      font-size: 3.2rem;
      height: 3.2rem;
      width: auto;
      @media screen and (min-width: $breakpoint-sm) {
        font-size: 4rem;
        height: 4rem;
      }
    }
  }
}

.wl-category {
  &__title {
    font-size: $font-size-16;
    font-weight: 700;
    word-break: break-word;
  }
  &__subtitle {
    font-size: $font-size-12;
    color: $reelax-dark-grey;
  }

}
