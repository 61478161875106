.confirmation-page{
  &__congrats{
    font-size: 2.4rem;
    font-weight: 600;
    &__img {
      font-size: 4.8rem !important;
      height: 4.8rem !important;
      width: auto !important;
    }
  }
  &__text{
    font-size: 1.4rem;
    font-weight: 400;
    word-break: break-word;
    @media screen and (min-width: $breakpoint-md) {
      font-size: 1.6rem;
    }

    &.--last{
      margin-bottom: 4rem;
    }
  }
}
